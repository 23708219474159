<template>
	<div>
		<a-drawer :zIndex="1000" :width="500" @close="onClose" :visible="filterDrawer.visible"
			:wrapStyle="{ overflow: 'auto', }">
			<bhLoading :show="loading" />
			<div class="h-full dF fC jSB" style="margin-bottom: 60px;">
				<div class="f1">
					<h5>Filters</h5>
					<div v-show="filterApplied" style="display:inline-block">
						<div @click="clearFilter" class="dF aC mt-4 py-2 px-4"
							style="border-radius:4px; border:1px solid #ECEBED; background-color:white; cursor:pointer">
							<div class="mr-3">Clear Filter</div><a-icon style="color:var(--danger)" type="delete" />
						</div>
					</div>
					<div class="mt-4">
						<div class="mt-4">
							<h6>Payment Status:</h6>
							<a-radio-group v-model="filter.paid">
								<a-radio-button :value="true">
									PAID
								</a-radio-button>
								<a-radio-button :value="false">
									UNPAID
								</a-radio-button>
							</a-radio-group>
						</div>
						<div class="mt-4">
							<h6>Invoice Currency:</h6>
							<a-radio-group v-model="filter.currency">
								<a-radio-button value="USD">
									$ USD
								</a-radio-button>
								<a-radio-button value="CAD">
									$ CAD
								</a-radio-button>
							</a-radio-group>
						</div>
						<div class="mt-4">
							<h6>Billing Owner(s):</h6>
							<!-- <a-select style="width: 100%" v-model="filter.billingOwner" mode="multiple"
								option-filter-prop="children" :filter-option="filterOption" @change="onBillingOwnerChange">
								<a-select-option value="any">Any</a-select-option>
								<a-select-option v-for="user in users" :key="user.id" :value="user.id">
									{{ user.firstName }}
									{{ user.lastName }}
									({{ user.email }})
								</a-select-option>
							</a-select> -->
							<a-select
								mode="multiple"
								option-label-prop="label"
								v-model="filter.billingOwner"
								style="width: 100%"
								:filterOption="false"
								@search="handleBillingSearch"
							>
								<a-select-option value="any" label="Any">Any</a-select-option>
								<a-select-option
									v-for="user in billingUserSearchResult"
									:key="user.value"
									:label="user.label"
									:value="user.value"
								>
									<div class="dF aC">
										<div class="f1">
											<div
												style="
													color: black;
													font-size: 15px;
												"
											>
												{{ user.label }}
											</div>
											<small>{{
												`<${user.email}>`
											}}</small>
										</div>
									</div>
								</a-select-option>
							</a-select>
						</div>
						<div class="mt-4">
							<h6>Project Name:</h6>
							<a-input placeholder="Search Project Name" v-model="filter.project" allowClear />
						</div>
						<div class="mt-4">
							<h6>Partner Name:</h6>
							<a-input placeholder="Search Partner Name" v-model="filter.partner" allowClear />
						</div>
						<div class="mt-4">
							<h6>Billing Name:</h6>
							<a-input placeholder="Search Billing Name" v-model="filter.billingName" allowClear />
						</div>
						<div class="mt-4">
							<h6>Invoice Number:</h6>
							<a-input-number style="width: 100%" placeholder="Search Invoice Number" v-model="filter.invoiceNumber" allowClear />
						</div>
					</div>
				</div>
				<div
					style="position: absolute; left: 0px; bottom: 0px; width: 100%; border-top: 1px solid rgb(233, 233, 233); padding: 10px 16px; background: rgb(255, 255, 255); text-align: right;">
					<div class="dF jE">
						<div class="dF ml-3">
							<a-button size="large" class="mr-3 cancel-button" @click="onClose">CANCEL</a-button>
							<a-button size="large" @click="submit" type="primary">APPLY FILTER</a-button>
						</div>
					</div>
				</div>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import moment from 'moment'
import bhLoading from 'bh-mod/components/common/Loading'
export default {
	components: { bhLoading },
	data() {
		return {
			billingUserSearchResult: [],
			tagSource: [],
			dateRange: undefined,
			loading: false,
			displayRange: '',
			filter: {},
			statusList: [{ name: 'Any', value: 'any' }, { name: 'Pending', value: 'pending' }, { name: 'Approved', value: 'approved' }, { name: 'Declined', value: 'declined' }]
		}
	},
	watch: {
		visible: {
			handler(val) {
				if (val) {
					this.filter = JSON.parse(JSON.stringify(this.filterDrawer.filter))
				}
			}
		}
	},
	computed: {
		users() {
			const data = this.$store.state.allUsers;

			return data.filter(u => u.email).sort((a, b) => a.email.localeCompare(b.email))
		},
		filterDrawer() {
			return this.$store.state.filterDrawer
		},
		visible() {
			return this.filterDrawer.visible
		},
		filterApplied() {
			if (
				this.filterDrawer.filter.project ||
				this.filterDrawer.filter.partner ||
				this.filterDrawer.filter.invoiceNumber ||
				this.filterDrawer.filter.billingName ||
				this.filterDrawer.filter.billingOwner[0] !== "any"
			) {
				return true
			}
			return false
		}
	},
	methods: {
		moment,

		handleBillingSearch(input) {
			console.log('Handle Search');
			if (input.length < 3) {
				return (this.timerId = undefined);
			}
			const makeAPICall = () => {
				this.updateBillingList(input);
			};

			if (this.timerId) clearTimeout(this.timerId);

			this.timerId = setTimeout(function () {
				makeAPICall();
				this.timerId = undefined;
			}, 400);
        },
		async updateBillingList(input) {
			try {
				let { data } = await this.$api.get(
					`/super-admin/users?_q=${input}&limited=true`
				);
				console.log("data", data);
				this.billingUserSearchResult = data.map(({ name, id, email }) => ({
					label: name,
					value: id,
					email: email,
				}));
				console.log("THIS SEARCH RESULT", this.billingUserSearchResult, data);
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
        },

		submit() {
			this.$store.commit("UPDATE_FILTER", this.filter);
			this.onClose();
		},

		clearFilter() {
			this.$store.commit('RESET_DEFAULT_FILTER')
			this.$store.commit('CLOSE_FILTER')
		},
		onClose() {
			this.$store.commit('CLOSE_FILTER')
		},

		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			);
		},

		onBillingOwnerChange(billingOwner) {
			billingOwner = billingOwner[billingOwner.length - 1];
			if (billingOwner && billingOwner != "any") {
				let index = this.filter.billingOwner.findIndex((x) => x == "any");
				if (index > -1) {
					this.filter.billingOwner.splice(index, 1);
				}
			} else {
				this.filter.billingOwner = ["any"];
			}
			if (this.filter.billingOwner.length == 0) {
				this.filter.billingOwner = ["any"];
			}
		},
	},

	mounted() {
		this.filter = JSON.parse(JSON.stringify(this.filterDrawer.filter))
	}
}
</script>

<style>
.range-display .ant-tag-blue {
	color: black !important;
}

.collapse-display.ant-collapse {
	border: none !important;
}

.collapse-display.ant-collapse>.ant-collapse-item {
	border-bottom: none !important;
}

.collapse-display .ant-collapse-header {
	padding: none !important;
}

.collapse-display.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
	padding: 0 !important;
}

.collapse-display .ant-collapse-content {
	border: none !important;
}

.cancel-button.ant-btn:hover {
	border-color: #ECE9F1 !important;
}
</style>
<style scoped>
.filter-options:hover {
	background-color: #ECE9F1;
}

.hide-display {
	height: 0;
	transition: height .3s ease-in-out;
	overflow: hidden;
}

.show-display {
	height: 200px;
}
</style>
