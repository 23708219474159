<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div>
			<BhLoading :show="loading" />
			<FilterDrawer />
			<a-tabs size="large" v-model="activeTab">
				<div slot="tabBarExtraContent">
					<div class="dF aC" style="gap: 25px">
						<a-button v-if="selectedInvoiceIds.length" :loading="loading"
							type="primary" @click="bulkPayment" ghost>Bulk Payment</a-button>
						<a-button v-if="selectedInvoiceIds.length" :loading="$store.state.downloadingInvoices"
							type="primary" @click="bulkDownload" ghost>Bulk Download</a-button>
						<div class="dF aC" style="gap: 5px">
							<span>Print:</span>
							<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
								Charts
							</a>
							<PDFOrientation class="ml-2" />
						</div>

						<DatePicker @current-times-update="fetchChartData($event)" :disabled-compare-date="true" />
						<a-button @click="showFilter">
							Filters <i class="fe fe-filter ml-2" />
						</a-button>
					</div>
				</div>
				<a-tab-pane key="1" tab="Overview">
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						:pdf-content-width="currentWidth" :manual-pagination="true" :html-to-pdf-options="{
							margin: [5, 5, 5, 5],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true, width: currentWidth },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<a-row :gutter="24">
								<a-col :span="5">
									<a-card>
										<a-statistic :value="subTotalPercentage" suffix="%"
											:value-style="{ color: '#F7941E' }">
											<template #title>
												<h3 style="color: #F7941E;">Sub Total</h3>
											</template>
											<template #prefix>
												<h4 class="mr-3" style="color: #F7941E">{{
													getHumanReadableNumber(totalRevenue.subTotal) }}
													{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
											</template>
										</a-statistic>
									</a-card>
								</a-col>
								<a-col :span="5">
									<a-card>
										<a-statistic :value="totalDiscountPercentage" suffix="%"
											:value-style="{ color: '#2B93C6' }">
											<template #title>
												<h3 style="color: #2B93C6;">Total Discount</h3>
											</template>
											<template #prefix>
												<h4 class="mr-3" style="color: #2B93C6">{{
													getHumanReadableNumber(totalRevenue.discount) }}
													{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
											</template>
										</a-statistic>
									</a-card>
								</a-col>
								<a-col :span="5">
									<a-card>
										<a-statistic :value="totalTaxPercentage" suffix="%"
											:value-style="{ color: '#FD637C' }">
											<template #title>
												<h3 style="color: #FD637C;">Total Tax On Sales</h3>
											</template>
											<template #prefix>
												<h4 class="mr-3" style="color: #FD637C">{{
													getHumanReadableNumber(totalRevenue.tax) }}
													{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
											</template>
										</a-statistic>
									</a-card>
								</a-col>
								<a-col :span="5">
									<a-card>
										<a-statistic :value="100" suffix="%" :value-style="{ color: '#1EC48C' }">
											<template #title>
												<h3 style="color: #1EC48C;">Total Amount</h3>
											</template>
											<template #prefix>
												<h4 class="mr-3" style="color: #1EC48C">{{
													getHumanReadableNumber(totalRevenue.total) }}
													{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
											</template>
										</a-statistic>
									</a-card>
								</a-col>
								<a-col :span="4">
									<a-card>
										<a-statistic :value="totalUnpaidPercentage" suffix="%" :value-style="{ color: '#cf2d38' }">
											<template #title>
												<h3 style="color: #cf2d38;">Total Unpaid</h3>
											</template>
											<template #prefix>
												<h4 class="mr-3" style="color: #cf2d38">{{
													getHumanReadableNumber(totalRevenue.unpaidTotal) }}
													{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
											</template>
										</a-statistic>
									</a-card>
								</a-col>
							</a-row>
							<a-card class="mt-3" :bordered="false" :loading="loading">
								<div class="dF aC" style="gap: 20px; flex-wrap: wrap;">
									<div style="font-weight:bold; font-size: large;">Current Applied Filters: </div>
									<div v-for="(filter, index) in currentFilters" :key="index + filter.value"
										style="font-weight:bold; background:var(--light-divider); border-radius:40px; font-size: 14px;"
										class="px-3 py-2">
										{{ filter.name }}: {{ filter.value }} <span v-if="filter.key"
											@click="removeFilter(filter.key)" style="cursor: pointer;"><a-icon
												type="close" /></span>
									</div>
								</div>
							</a-card>
							<div class="mt-3">
								<div v-if="!error">
									<a-card :bordered="false" :loading="loading">
										<div class="dF aC jSB mb-3">
											<h4>
												Package Sales Revenue
											</h4>
											<div class="font-size-18">
												{{ selectedDateRange }}
											</div>
											<div data-html2canvas-ignore="true">
												<span class="mr-3">Chart Type:</span>
												<a-radio-group button-style="solid" v-model="revenueChartType.package">
													<a-radio-button value="line">
														Line
													</a-radio-button>
													<a-radio-button value="bar">
														Bar
													</a-radio-button>
												</a-radio-group>
											</div>
										</div>
										<eChartLine :option="packageChartOptions" :key="'title'" />
									</a-card>
								</div>
								<div v-else class="mt-10" style="text-align: center">
									<h4>Something went wrong. Please Try again!</h4>
									<a-button type="primary" @click="fetchChartData">Retry</a-button>
								</div>
							</div>
							<div class="dF aC mt-5" style="flex-wrap: wrap;">
								<a-card class="mr-3 mb-3">
									<a-statistic :value="100" suffix="%" :value-style="{ color: '#2B93C6' }">
										<template #title>
											<h3 style="color: #2B93C6;">Total Package Fee</h3>
										</template>
										<template #prefix>
											<h4 class="mr-3" style="color: #2B93C6">{{
												getHumanReadableNumber(packageChartOptions.totalPackageFees) }}
												{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
										</template>
									</a-statistic>
								</a-card>
								<a-card class="mr-3 mb-3">
									<a-statistic :value="100" suffix="%" :value-style="{ color: '#F7941E' }">
										<template #title>
											<h3 style="color: #F7941E;">Total AddOn Fee</h3>
										</template>
										<template #prefix>
											<h4 class="mr-3" style="color: #F7941E">{{
												getHumanReadableNumber(addOnsChartOptions.totalAddOnFees) }}
												{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
										</template>
									</a-statistic>
								</a-card>
								<a-card class="mr-3 mb-3">
									<a-statistic :value="APICallFeesPercentage" suffix="%"
										:value-style="{ color: '#2B93C6' }">
										<template #title>
											<h3 style="color: #2B93C6;">Total API Call Fees</h3>
										</template>
										<template #prefix>
											<h4 class="mr-3" style="color: #2B93C6">{{
												getHumanReadableNumber(addOnsChartOptions.totalAPICallFees) }}
												{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
										</template>
									</a-statistic>
								</a-card>
								<a-card class="mr-3 mb-3">
									<a-statistic :value="lotDoorFeesPercentage" suffix="%"
										:value-style="{ color: '#1EC48C' }">
										<template #title>
											<h3 style="color: #1EC48C;">Total Lot Door Fees</h3>
										</template>
										<template #prefix>
											<h4 class="mr-3" style="color: #1EC48C">{{
												getHumanReadableNumber(addOnsChartOptions.totalLotDoorFees) }}
												{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
										</template>
									</a-statistic>
								</a-card>
								<a-card class="mr-3 mb-3">
									<a-statistic :value="onBoardingFeesPercentage" suffix="%"
										:value-style="{ color: '#FD637C' }">
										<template #title>
											<h3 style="color: #FD637C;">Total Onboarding Fees</h3>
										</template>
										<template #prefix>
											<h4 class="mr-3" style="color: #FD637C">{{
												getHumanReadableNumber(addOnsChartOptions.totalOnboardingFees) }}
												{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
										</template>
									</a-statistic>
								</a-card>
								<a-card class="mr-3 mb-3">
									<a-statistic :value="videoChatSupportFeesPercentage" suffix="%"
										:value-style="{ color: '#FFC300' }">
										<template #title>
											<h3 style="color: #FFC300;">Total Video Chat Support Fees</h3>
										</template>
										<template #prefix>
											<h4 class="mr-3" style="color: #FFC300">{{
												getHumanReadableNumber(addOnsChartOptions.totalVideoChatSupportFees) }}
												{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
										</template>
									</a-statistic>
								</a-card>
								<a-card class="mr-3 mb-3">
									<a-statistic :value="setupFeesPercentage" suffix="%"
										:value-style="{ color: '#9693E8' }">
										<template #title>
											<h3 style="color: #9693E8;">Total Setup Fees</h3>
										</template>
										<template #prefix>
											<h4 class="mr-3" style="color: #9693E8">{{
												getHumanReadableNumber(addOnsChartOptions.totalSetupFees) }}
												{{ filter.currency === 'CAD' ? '(CAD)' : '' }}</h4>
										</template>
									</a-statistic>
								</a-card>
							</div>
							<div class="mt-3">
								<div v-if="!error">
									<a-card :bordered="false" :loading="loading">
										<div class="dF aC jSB mb-3">
											<h4>
												AddOns Sales Revenue
											</h4>
											<div class="font-size-18">
												{{ selectedDateRange }}
											</div>
											<div data-html2canvas-ignore="true">
												<span class="mr-3">Chart Type:</span>
												<a-radio-group button-style="solid" v-model="revenueChartType.addOns">
													<a-radio-button value="line">
														Line
													</a-radio-button>
													<a-radio-button value="bar">
														Bar
													</a-radio-button>
												</a-radio-group>
											</div>
										</div>
										<eChartLine :option="addOnsChartOptions" :key="'title'" />
									</a-card>
								</div>
								<div v-else class="mt-10" style="text-align: center">
									<h4>Something went wrong. Please Try again!</h4>
									<a-button type="primary" @click="fetchChartData">Retry</a-button>
								</div>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
				<a-tab-pane key="2" tab="Invoice Details">
					<a-card :bodyStyle="{ padding: 0 }" class="my-3">
						<div>
							<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="invoices" :loading="loading"
								:pagination="{
									current: currentPage,
									total: totalInvoices,
									pageSize: pageSize,
									showSizeChanger: true,
									pageSizeOptions: ['10', '20', '30', '40', '50', '75', '100']
								}" :row-selection="{ selectedRowKeys: selectedInvoiceIds, onChange: onSelectChange }" @change="handleChange"
								:scroll="{ x: 750 }">
								<div slot="name" slot-scope="invoice">
									{{ invoice.instance && invoice.instance.name }}
								</div>
								<div slot="invoiceNumber" slot-scope="invoice">
									{{ invoice.invoiceNumber }}
								</div>
								<div slot="invoiceDate" slot-scope="invoice" style="color: var(--med-gray)">
									{{ !invoice.createdAt ? "N/A" : getDate(invoice.createdAt) }}
								</div>
								<div slot="planType" slot-scope="invoice">
									{{ invoice.details && invoice.details.package && invoice.details.package.name }}
								</div>
								<div slot="packageFee" slot-scope="invoice">
									{{ invoice.details && invoice.details.package && invoice.details.package.price &&
										getHumanReadableNumber(invoice.details.package.price) }}
								</div>
								<div slot="addOnsFee" slot-scope="invoice">
									<div v-for="(addon, index) in getAddOns(invoice)" :key="index">
										<span>{{ addon.name }} - {{ getHumanReadableNumber(addon.price) }}</span>
									</div>
								</div>
								<div slot="subTotal" slot-scope="invoice">
									{{ getHumanReadableNumber(invoice.subTotal) }}
								</div>
								<div slot="tax" slot-scope="invoice">
									{{ getHumanReadableNumber(invoice.tax) }}
								</div>
								<div slot="total" slot-scope="invoice">
									{{ getHumanReadableNumber(invoice.subTotal + invoice.tax) }}
								</div>
								<div slot="partner" slot-scope="invoice">
									<span v-if="invoice.instance && invoice.instance.partner" class="project-button"
										style="background-color: #dddcfc;color: #7874e9">{{
											invoice.instance.partner.companyName }}</span>
								</div>
								<div slot="discount" slot-scope="invoice">
									<div
										v-if="invoice.details && invoice.details.appliedDiscounts && invoice.details.appliedDiscounts.length">
										<div v-for="(dic, index) in invoice.details.appliedDiscounts" :key="index">
											<span>{{ dic.name }} - {{ getHumanReadableNumber(dic.price) }}</span>
										</div>
									</div>
									<div
										v-if="invoice.details && invoice.details.deductions && invoice.details.deductions.length">
										<div v-for="(dic, index) in invoice.details.deductions" :key="index">
											<span>{{ dic.name }} - {{ getHumanReadableNumber(dic.price) }}</span>
										</div>
									</div>
								</div>
								<div slot="actualCharge" slot-scope="invoice">
									{{ getHumanReadableNumber(invoice.total) }}
								</div>
								<div slot="recurring" slot-scope="invoice">
									{{ getRecurringFee(invoice) }} / {{ invoice.instance && invoice.instance.recurrence ===
										'yearly' ? 'Yearly' : 'Monthly' }}
								</div>
								<div slot="currency" slot-scope="invoice">
									{{ invoice.currency || 'USD' }}
								</div>
								<div slot="status" slot-scope="invoice">
									<span class="project-button py-1 px-3" :style="invoice.paid
										? 'background-color:#28C791'
										: 'background-color:#db5358'
										">
										{{ invoice.paid ? "PAID" : "UNPAID" }}</span>
								</div>
								<div slot="renewDate" slot-scope="invoice" style="color: var(--med-gray)">
									{{ !invoice.validUntil ? "N/A" : getDate(+invoice.validUntil) }}
								</div>
							</a-table>
						</div>
					</a-card>
				</a-tab-pane>
			</a-tabs>
			<a-modal v-model="bulkPaymentModal.show" title="Offline Invoice Payment" ok-text="SUBMIT"
				@ok="createBulkPayment" @cancel="resetInvoicePaymentData" :confirmLoading="loading" :width="800">
				<a-form-model ref="invoicePayment" :model="bulkPaymentModal.invoicePayment" class="profile">
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Payment Date" required prop="paymentDate"
								:rules="req('Please enter the Payment Date')">
								<a-date-picker v-model="bulkPaymentModal.invoicePayment.paymentDate"
									placeholder="Payment Date" size="large" style="width: 100%">
								</a-date-picker>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Payment Mode" prop="paymentMode" required
								:rules="req('Please select the Payment mode')">
								<a-select v-model="bulkPaymentModal.invoicePayment.paymentMode" placeholder="Payment Mode"
									size="large">
									<a-select-option value="cash">Cash</a-select-option>
									<a-select-option value="check">Check</a-select-option>
									<a-select-option value="bank_transfer">Bank Transfer</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Note" prop="note">
								<a-textarea v-model="bulkPaymentModal.invoicePayment.note" placeholder="Note" :rows="3">
								</a-textarea>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16" v-if="bulkPaymentModal.invoicePayment.paymentMode === 'check'">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Check Number" prop="metadata.checkNumber">
								<a-input v-model="bulkPaymentModal.invoicePayment.metadata.checkNumber"
									placeholder="Check Number" style="width: 100%" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Bank Name" prop="metadata.bankName">
								<a-input v-model="bulkPaymentModal.invoicePayment.metadata.bankName" placeholder="Bank Name"
									style="width: 100%" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Check Date" prop="metadata.checkDate">
								<a-date-picker v-model="bulkPaymentModal.invoicePayment.metadata.checkDate"
									placeholder="Check Date" size="large" style="width: 100%">
								</a-date-picker>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16" v-if="bulkPaymentModal.invoicePayment.paymentMode === 'bank_transfer'">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Debit Bank Account Number" prop="metadata.fromAccountNumber">
								<a-input-number v-model="bulkPaymentModal.invoicePayment.metadata.fromAccountNumber"
									placeholder="Debit Bank Account Number" style="width: 100%" size="large">
								</a-input-number>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Deposit Bank Account Number" prop="metadata.toAccountNumber">
								<a-input-number v-model="bulkPaymentModal.invoicePayment.metadata.toAccountNumber"
									placeholder="Deposit Bank Account Number" style="width: 100%" size="large">
								</a-input-number>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Transfer Date" prop="metadata.transferDate">
								<a-date-picker v-model="bulkPaymentModal.invoicePayment.metadata.transferDate"
									placeholder="Transfer Date" size="large" style="width: 100%">
								</a-date-picker>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16" v-if="bulkPaymentModal.invoicePayment.paymentMode === 'cash'">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Received From" prop="metadata.receivedFrom">
								<a-input v-model="bulkPaymentModal.invoicePayment.metadata.receivedFrom"
									placeholder="Received From" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Received By" prop="metadata.receivedBy">
								<a-input v-model="bulkPaymentModal.invoicePayment.metadata.receivedBy"
									placeholder="Received By" size="large">
								</a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="16" v-for="(invoice, index) in bulkPaymentModal.invoicePayment.invoices"
						:key="invoice.id">
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Invoice Number" required>
								<a-input v-model="bulkPaymentModal.invoicePayment.invoices[index].invoiceNumber"
									placeholder="Invoice Number" size="large" type="number" disabled>
								</a-input>
								</a-date-picker>
							</a-form-model-item>
						</a-col>
						<a-col :span="$mq == 'sm' ? 24 : 12">
							<a-form-model-item label="Amount" required :prop="`invoices[${index}].amount`" :rules="req('Please enter the amount')
								">
								<a-input v-model="bulkPaymentModal.invoicePayment.invoices[index].amount"
									placeholder="Invoice amount" style="width: 100%" size="large" type="number" prefix="$"
									:addon-after="bulkPaymentModal.invoicePayment.invoices[index].currency || 'USD'">
								</a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
			</a-modal>
			<MultipleInvoicePDF @completed="selectedInvoiceIds = []" />
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import BhLoading from "bh-mod/components/common/Loading";
import DatePicker from "@/components/common/datePicker.vue";
import PDFOrientation from "@/components/common/pdfOrientation.vue";
import eChartLine from "@/components/common/eChartLine";
import _ from "lodash";
import MultipleInvoicePDF from "@/components/common/MultipleInvoicePDF";
import FilterDrawer from '@/components/common/FilterDrawer'


export default {
	components: { BhLoading, DatePicker, PDFOrientation, VueHtml2pdf, eChartLine, MultipleInvoicePDF, FilterDrawer },

	data() {
		return {
			loading: false,
			error: false,
			activeTab: '1',
			currentPage: 1,
			totalInvoices: 0,
			pageSize: 10,
			currentWidth: window.innerWidth,
			revenueData: {},
			revenueChartType: {
				package: 'line',
				addOns: 'line',
			},
			chartOptions: {
				title: {
					text: '',
					left: 'center'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					top: 'bottom'
				},
				grid: {
					left: '1%',
					right: '1%',
					top: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: {
					type: 'value'
				},
				series: []
			},
			defaultSeriesOption: {
				name: '',
				type: 'bar',
				data: [],
				smooth: true,
				color: "#2B93C6",
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			},
			sortedInfo: {
				key: "createdAt",
				order: "DESC",
			},
			invoices: [],
			columns: [
				{
					title: "Project Name",
					key: "instance.name",
					scopedSlots: { customRender: "name" },
					sorter: true,
				},
				{
					title: "Invoice #",
					key: "invoiceNumber",
					scopedSlots: { customRender: "invoiceNumber" },
					sorter: true,
				},
				{
					title: "Invoice Date",
					key: "createdAt",
					scopedSlots: { customRender: "invoiceDate" },
					sorter: true,
				},
				{
					title: "Plan Type",
					key: "package.name",
					scopedSlots: { customRender: "planType" },
				},
				{
					title: "Package Fee",
					key: "package.price",
					scopedSlots: { customRender: "packageFee" },
				},
				{
					title: "AddOns Fee",
					key: "addOnsFee",
					scopedSlots: { customRender: "addOnsFee" },
					// width: '12%'
				},
				{
					title: "Sub Total",
					key: "subTotal",
					scopedSlots: { customRender: "subTotal" },
					sorter: true,
				},
				{
					title: "Tax",
					key: "tax",
					scopedSlots: { customRender: "tax" },
					sorter: true,
					// width: '5%'
				},
				{
					title: "Total",
					key: "total",
					scopedSlots: { customRender: "total" },
					sorter: true,
				},
				{
					title: "Partner",
					key: "instance.partner.companyName",
					scopedSlots: { customRender: "partner" },
				},
				{
					title: "Discount",
					key: "discount",
					scopedSlots: { customRender: "discount" },
				},
				{
					title: "Actual Charge",
					key: "actualCharge",
					scopedSlots: { customRender: "actualCharge" },
				},
				{
					title: "Recurring Fee",
					key: "recurring",
					scopedSlots: { customRender: "recurring" },
				},
				{
					title: "$",
					key: "currency",
					scopedSlots: { customRender: "currency" },
					sorter: true,
					// width: '5%'
				},
				{
					title: "Renewal Date",
					key: "validUntil",
					scopedSlots: { customRender: "renewDate" },
					sorter: true,
				},
			],
			selectedInvoiceIds: [],
			bulkPaymentModal: {
				show: false,
				invoicePayment: {
					invoices: [],
					paymentDate: null,
					note: "",
					paymentMode: "check",
					metadata: {
						checkNumber: "",
						bankName: "",
						fromAccountNumber: "",
						toAccountNumber: "",
						checkDate: null,
						transferDate: null,
						receivedFrom: "",
						receivedBy: ""
					}
				}
			},
		}
	},

	computed: {
		...mapState(["currentStartDate", "currentEndDate", "pdfOrientation"]),

		totalRevenue() {
			return this.revenueData && this.revenueData.totalRevenue || {}
		},

		subTotalPercentage() {
			let total = this.totalRevenue.total;
			let subTotal = this.totalRevenue.subTotal;
			if (subTotal && total) {
				return ((subTotal / total) * 100).toFixed(2)
			}
			return 0
		},

		totalDiscountPercentage() {
			let total = this.totalRevenue.total;
			let discount = this.totalRevenue.discount;
			if (discount && total) {
				return ((discount / total) * 100).toFixed(2)
			}
			return 0
		},

		totalTaxPercentage() {
			let total = this.totalRevenue.total;
			let tax = this.totalRevenue.tax;
			if (tax && total) {
				return ((tax / total) * 100).toFixed(2)
			}
			return 0
		},

		totalUnpaidPercentage() {
			let total = this.totalRevenue.total;
			let unpaidTotal = this.totalRevenue.unpaidTotal;
			if (unpaidTotal && total) {
				return ((unpaidTotal / total) * 100).toFixed(2)
			}
			return 0
		},

		APICallFeesPercentage() {
			let totalAddOnFees = this.addOnsChartOptions.totalAddOnFees;
			let totalAPICallFees = this.addOnsChartOptions.totalAPICallFees;
			if (totalAPICallFees && totalAddOnFees) {
				return ((totalAPICallFees / totalAddOnFees) * 100).toFixed(2)
			}
			return 0
		},

		lotDoorFeesPercentage() {
			let totalAddOnFees = this.addOnsChartOptions.totalAddOnFees;
			let totalLotDoorFees = this.addOnsChartOptions.totalLotDoorFees;
			if (totalLotDoorFees && totalAddOnFees) {
				return ((totalLotDoorFees / totalAddOnFees) * 100).toFixed(2)
			}
			return 0
		},

		onBoardingFeesPercentage() {
			let totalAddOnFees = this.addOnsChartOptions.totalAddOnFees;
			let totalOnboardingFees = this.addOnsChartOptions.totalOnboardingFees;
			if (totalOnboardingFees && totalAddOnFees) {
				return ((totalOnboardingFees / totalAddOnFees) * 100).toFixed(2)
			}
			return 0
		},

		videoChatSupportFeesPercentage() {
			let totalAddOnFees = this.addOnsChartOptions.totalAddOnFees;
			let totalVideoChatSupportFees = this.addOnsChartOptions.totalVideoChatSupportFees;
			if (totalVideoChatSupportFees && totalAddOnFees) {
				return ((totalVideoChatSupportFees / totalAddOnFees) * 100).toFixed(2)
			}
			return 0
		},

		setupFeesPercentage() {
			let totalAddOnFees = this.addOnsChartOptions.totalAddOnFees;
			let totalSetupFees = this.addOnsChartOptions.totalSetupFees;
			if (totalSetupFees && totalAddOnFees) {
				return ((totalSetupFees / totalAddOnFees) * 100).toFixed(2)
			}
			return 0
		},


		packageChartOptions() {
			let options = JSON.parse(JSON.stringify(this.chartOptions))
			options.xAxis.data = []
			options.series = [];
			options.totalPackageFees = 0;
			options.totalDiscountAmount = 0;

			options.series.push({
				...this.defaultSeriesOption,
				type: this.revenueChartType.package,
				name: 'Package Fees',
				data: []
			})

			options.series.push({
				...this.defaultSeriesOption,
				type: this.revenueChartType.package,
				name: 'Discount Amount',
				data: [],
				color: "#1EC48C",
			})

			if (this.revenueData.packageRevenue) {
				const packageData = this.revenueData.packageRevenue
				packageData.forEach(pr => {
					if (pr.packageFeeTotal || pr.discountsTotal) {
						options.xAxis.data.push(moment(pr.date).format('ll'))
						options.totalPackageFees += pr.packageFeeTotal
						options.totalDiscountAmount += pr.discountsTotal
						options.series[0].data.push(+pr.packageFeeTotal.toFixed(2))
						options.series[1].data.push(+pr.discountsTotal.toFixed(2))
					}
				})
			}

			return options
		},

		addOnsChartOptions() {
			let options = JSON.parse(JSON.stringify(this.chartOptions))
			options.xAxis.data = []
			options.series = [];
			options.totalAddOnFees = 0;
			options.totalAPICallFees = 0;
			options.totalLotDoorFees = 0;
			options.totalOnboardingFees = 0;
			options.totalVideoChatSupportFees = 0;
			options.totalSetupFees = 0;

			options.series.push({
				...this.defaultSeriesOption,
				type: this.revenueChartType.addOns,
				name: 'API Call Fees',
				data: []
			})

			options.series.push({
				...this.defaultSeriesOption,
				type: this.revenueChartType.addOns,
				name: 'Lot Door Fees',
				data: [],
				color: "#1EC48C",
			})

			options.series.push({
				...this.defaultSeriesOption,
				type: this.revenueChartType.addOns,
				name: 'Onboarding Fees',
				data: [],
				color: "#FD637C",
			})

			options.series.push({
				...this.defaultSeriesOption,
				type: this.revenueChartType.addOns,
				name: 'Video Chat Support Fees',
				data: [],
				color: "#FFC300",
			})

			options.series.push({
				...this.defaultSeriesOption,
				type: this.revenueChartType.addOns,
				name: 'Setup Fees',
				data: [],
				color: "#9693E8",
			})

			if (this.revenueData.addOnsRevenue || this.revenueData.usageRevenue) {
				const addOnData = this.revenueData.addOnsRevenue || []
				const usageData = this.revenueData.usageRevenue || [];

				usageData.forEach((usage) => {
					const index = addOnData.findIndex((addOn) => addOn.date == usage.date)
					if (index > -1) {
						addOnData[index] = {
							...addOnData[index],
							setupFeeTotal: usage.setupFeeTotal
						}
					} else {
						addOnData.push({
							date: usage.date,
							setupFeeTotal: usage.setupFeeTotal,
							apiCallsTotal: 0,
							lotDoorFeeTotal: 0,
							onboardingTotal: 0,
							videoChatSupportFeeTotal: 0
						})
					}
				})

				addOnData.sort((a, b) => moment(a.date).format('x') - moment(b.date).format('x'))

				addOnData.forEach(pr => {
					if (pr.apiCallsTotal || pr.lotDoorFeeTotal || pr.onboardingTotal || pr.videoChatSupportFeeTotal || pr.setupFeeTotal) {
						options.xAxis.data.push(moment(pr.date).format('ll'))
						options.totalAPICallFees += (pr.apiCallsTotal || 0)
						options.totalLotDoorFees += (pr.lotDoorFeeTotal || 0)
						options.totalOnboardingFees += (pr.onboardingTotal || 0)
						options.totalVideoChatSupportFees += (pr.videoChatSupportFeeTotal || 0)
						options.totalSetupFees += (pr.setupFeeTotal || 0)
						options.series[0].data.push(+pr.apiCallsTotal.toFixed(2))
						options.series[1].data.push(+pr.lotDoorFeeTotal.toFixed(2))
						options.series[2].data.push(+pr.onboardingTotal.toFixed(2))
						options.series[3].data.push(+pr.videoChatSupportFeeTotal.toFixed(2))
						options.series[4].data.push(+(pr.setupFeeTotal || 0).toFixed(2))
					}
				})
				options.totalAddOnFees = options.totalAPICallFees + options.totalLotDoorFees + options.totalOnboardingFees + options.totalVideoChatSupportFees + options.totalSetupFees
			}

			return options
		},

		filter() {
			return this.$store.state.filterDrawer.filter
		},

		users() {
			const data = this.$store.state.allUsers;

			return data.filter(u => u.email).sort((a, b) => a.email.localeCompare(b.email))
		},

		selectedDateRange() {
			return `${moment(+this.currentStartDate).format('ll')} - ${moment(+this.currentEndDate).format('ll')}`
		},

		currentFilters() {
			let filters = [
				{ name: 'Date Range', value: this.selectedDateRange },
				{ name: 'Payment Status', value: this.filter.paid ? 'Paid' : 'Unpaid' },
				{ name: 'Invoice Currency', value: this.filter.currency }
			]

			if (this.filter.project) {
				filters.push({ name: 'Project', value: this.filter.project, key: 'project' })
			}

			if (this.filter.partner) {
				filters.push({ name: 'Partner', value: this.filter.partner, key: 'partner' })
			}

			if (this.filter.invoiceNumber) {
				filters.push({ name: 'Invoice Number', value: this.filter.invoiceNumber, key: 'invoiceNumber' })
			}

			if (this.filter.billingName) {
				filters.push({ name: 'Billing Name', value: this.filter.billingName, key: 'billingName' })
			}

			if (this.filter.billingOwner[0] !== "any") {
				let owners = []
				this.filter.billingOwner.forEach(userId => {
					const user = this.users.find(user => user.id === userId)
					if (user) {
						owners.push(user.email)
					}
				})
				filters.push({ name: 'Billing Owner(s)', value: owners.join(', '), key: 'billingOwner' })
			}

			return filters
		}
	},

	watch: {
		filter: {
			handler(val) {
				this.currentPage = 1
				this.fetchChartData({ startDat: this.currentStartDate, endDate: this.currentEndDate });
			},
			deep: true,
		}
	},


	methods: {
        req: (msg) => ({ required: true, message: msg }),

		getDate(date) {
			return moment(date).format("DD/MM/YYYY");
		},

		getHumanReadableNumber(number) {
			return (number || number == 0) && `$${number.toLocaleString(
				"en-US",
				{
					useGrouping: true,
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}
			)}` || 0
		},

		async fetchChartData({ startDate = this.currentStartDate, endDate = this.currentEndDate }) {
			this.error = false;
			this.loading = true;
			this.getTotalInvoiceCount();
			this.getInvoices();
			try {
				let searchQuery = `paid=${this.filter.paid}&currency=${this.filter.currency}&start=${startDate}&end=${endDate}`;

				if (this.filter.billingOwner[0] !== "any") {
					searchQuery += this.filter.billingOwner.map(u => `&instance.billingOwner_in=${u}`).join('');
				}
				if (this.filter.project) {
					searchQuery += `&instance.name_contains=${this.filter.project.trim().toLowerCase()}`;
				}
				if (this.filter.partner) {
					searchQuery += `&instance.partner.companyName_contains=${this.filter.partner.trim().toLowerCase()}`;
				}
				if (this.filter.billingName) {
					searchQuery += `&instance.billingName_contains=${this.filter.billingName.trim().toLowerCase()}`;
				}
				if (this.filter.invoiceNumber) {
					searchQuery += `&invoiceNumber_numeric=${Number(this.filter.invoiceNumber)}`;
				}
				const { data } = await this.$api.get(`super-admin/revenue-reports?${searchQuery}`)
				this.revenueData = data || {};
			} catch (err) {
				this.loading = false;
				this.error = true;
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching domain details. Please try again.`))
				}
			}
			this.loading = false;
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = `Revenue report -${Date.now()}.pdf`;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},

		searchDebounce: _.debounce(function () {
			this.currentPage = 1
			this.getInvoices();
			this.getTotalInvoiceCount();
		}, 1000),

		async getTotalInvoiceCount() {
			try {
				let searchQuery = `?paid=${this.filter.paid}&currency=${this.filter.currency}&createdAt_gte=${moment(+this.currentStartDate).toISOString()}&createdAt_lte=${moment(+this.currentEndDate).toISOString()}`;
				if (this.filter.billingOwner[0] !== "any") {
					searchQuery += this.filter.billingOwner.map(u => `&instance.billingOwner_in=${u}`).join('');
				}
				if (this.filter.project) {
					searchQuery += `&instance.name_contains=${this.filter.project.trim().toLowerCase()}`;
				}
				if (this.filter.partner) {
					searchQuery += `&instance.partner.companyName_contains=${this.filter.partner.trim().toLowerCase()}`;
				}
				if (this.filter.billingName) {
					searchQuery += `&instance.billingName_contains=${this.filter.billingName.trim().toLowerCase()}`;
				}
				if (this.filter.invoiceNumber) {
					searchQuery += `&invoiceNumber=${Number(this.filter.invoiceNumber)}`;
				}
				let { data } = await this.$api.get(`super-admin/invoices/count${searchQuery}`);
				this.totalInvoices = data;
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching invoice count. Please try again!"))
				}
			}
		},
		async getInvoices() {
			this.selectedInvoiceIds = [];
			this.loading = false;

			this.loading = true;
			try {
				let start = (this.currentPage - 1) * this.pageSize;
				let searchQuery = `paid=${this.filter.paid}&currency=${this.filter.currency}&createdAt_gte=${moment(+this.currentStartDate).toISOString()}&createdAt_lte=${moment(+this.currentEndDate).toISOString()}&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}`;

				if (this.filter.billingOwner[0] !== "any") {
					searchQuery += this.filter.billingOwner.map(u => `&instance.billingOwner_in=${u}`).join('');
				}
				if (this.filter.project) {
					searchQuery += `&instance.name_contains=${this.filter.project.trim().toLowerCase()}`;
				}
				if (this.filter.partner) {
					searchQuery += `&instance.partner.companyName_contains=${this.filter.partner.trim().toLowerCase()}`;
				}
				if (this.filter.billingName) {
					searchQuery += `&instance.billingName_contains=${this.filter.billingName.trim().toLowerCase()}`;
				}
				if (this.filter.invoiceNumber) {
					searchQuery += `&invoiceNumber=${Number(this.filter.invoiceNumber)}`;
				}
				let { data } = await this.$api.get(`/super-admin/invoices?${searchQuery}`);
				this.invoices = data;
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching invoice list. Please try again!"))
				}
			}
			this.loading = false;
		},

		handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
				this.pageSize = pagination.pageSize;
			}
			if (sorter && sorter.column) {
				if (
					sorter.column.title === "Status" &&
					sorter.order !== "descend"
				) {
					sorter.columnKey = "susspended";
				}
				this.sortedInfo = {
					key: sorter.columnKey,
					order: sorter.order === "descend" ? "DESC" : "ASC",
				};
			}
			this.getInvoices();
		},

		getAddOns(invoice) {
			let result = [];
			if (invoice && invoice.details) {
				if (invoice.details.selectedPackageExtras && invoice.details.selectedPackageExtras.length) {
					invoice.details.selectedPackageExtras.forEach(pe => {
						let name = pe.name
						if (name.includes('API Calls')) {
							name = 'API Calls'
						}
						if (name.includes('Chat Support')) {
							name = 'Support Fee'
						}
						result.push({
							...pe,
							name
						})
					})
				}
				if (invoice.details.featureBlocks && invoice.details.featureBlocks.length) {
					invoice.details.featureBlocks.forEach((fb, index) => {
						let quality = fb.value;
						if (typeof quality === 'string') {
							let arr = fb.value.split(" ")
							if (arr.length) {
								quality = +arr[0] / 4
							}
						}
						result.push({
							id: fb.name + "-" + index,
							name: fb.name,
							quantity: quality,
							price: fb.price
						})
					})
				}
				if (invoice.details.usageDetails) {
					const usageDetails = invoice.details.usageDetails;
					const qualityOpenIndex = usageDetails.name.indexOf("(");
					const qualityCloseIndex = usageDetails.name.indexOf(")");
					const name = qualityOpenIndex && qualityCloseIndex && usageDetails.name ? usageDetails.name.substring(0, qualityOpenIndex) : usageDetails.name;
					const quantity = qualityOpenIndex && qualityCloseIndex && usageDetails.quantity ? usageDetails.quantity.substring(qualityOpenIndex, qualityCloseIndex) : null;

					result.push({
						id: usageDetails.itemCode,
						name: name,
						quantity: quantity,
						price: usageDetails.price
					})
				}
			}
			return result
		},

		getRecurringFee(invoice) {
			if (invoice && invoice.details) {
				let total = invoice.details.package && invoice.details.package.price || 0;

				if (invoice.details.selectedPackageExtras) {
					invoice.details.selectedPackageExtras.forEach(p => {
						if (['Application Programming Interface (API Calls)', 'API Calls', 'Online Video Chat Support'].includes(p.name)) {
							total += p.price;
						}
					})
				}

				return this.getHumanReadableNumber(total);
			}
		},

		onSelectChange(selectedRowKeys) {
			this.selectedInvoiceIds = selectedRowKeys;
		},

		bulkDownload() {
			if (!this.selectedInvoiceIds.length || this.$store.state.downloadingInvoices) {
				return
			}
			const invoices = this.invoices.filter(invoice => this.selectedInvoiceIds.includes(invoice.id))

			this.$store.commit("SET_INVOICES", invoices)
		},

		showFilter() {
			this.$store.commit('OPEN_FILTER')
		},

		removeFilter(filterKey) {
			let defaultFilterValue = {
				paid: true,
				currency: 'USD',
				billingOwner: ['any'],
				project: '',
				partner: '',
				billingName: '',
				invoiceNumber: null,
			}

			let filter = JSON.parse(JSON.stringify(this.filter))

			filter[filterKey] = defaultFilterValue[filterKey]

			this.$store.commit("UPDATE_FILTER", filter);
		},

		bulkPayment() {
			if (!this.selectedInvoiceIds.length) {
				return this.$message.warn("Please select invoices");
			}

			let invoices = this.invoices.filter((invoice) => this.selectedInvoiceIds.includes(invoice.id) && !invoice.paid)

			if(invoices.length === 0){
				return this.$message.warn("No valid invoices found. Please select unpaid invoices from list.");
			}

			invoices.forEach(invoice => {
				let total = invoice.total
				if (invoice.payments && invoice.payments.length) {
					const totalPayments = invoice.payments.reduce((total, nextValue) => total + nextValue.amount, 0)
					total = total - totalPayments;
				}

				this.bulkPaymentModal.invoicePayment.paymentDate = moment()

				this.bulkPaymentModal.invoicePayment.invoices.push({
					id: invoice.id,
					amount: total,
					invoiceNumber: invoice.invoiceNumber,
					currency: invoice.currency
				})
			})

			this.bulkPaymentModal.show = true;
		},

		async createBulkPayment() {
			this.loading = true;
			try {
				let payload = JSON.parse(JSON.stringify(this.bulkPaymentModal.invoicePayment))
				payload.invoices = payload.invoices.map((invoice) => {
					return {
						id: invoice.id,
						amount: +invoice.amount,
					}
				})
				payload.paymentDate = +moment(payload.paymentDate).format("x");
				await this.$api.post(`invoice-payments/bulk-create`, payload)
				this.loading = false;
				this.$message.success("Invoices payment record successfully created.");
				this.getTotalInvoiceCount();
				this.getInvoices();
				this.resetInvoicePaymentData();
			} catch (err) {
				this.loading = false;
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while trying create bulk payment records. Please try again.`))
				}
			}
		},

		resetInvoicePaymentData() {
			this.bulkPaymentModal = {
				show: false,
				invoicePayment: {
					invoices: [],
					paymentDate: null,
					note: "",
					paymentMode: "check",
					metadata: {
						checkNumber: "",
						bankName: "",
						fromAccountNumber: "",
						toAccountNumber: "",
						checkDate: null,
						transferDate: null,
						receivedFrom: "",
						receivedBy: ""
					}
				}
			}

			this.selectedInvoiceIds = [];
		},
	},

	created() {
		// this.$store.dispatch('FETCH_ALL_USERS')
	}
}
</script>

<style lang="scss" scoped>
.project-button {
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	text-align: center;
	padding: 2px 8px;
}
</style>
